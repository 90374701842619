<template>
<div>
    <b-modal size="xl" id="modal-subcription" ref="modal-subcription" hide-footer title="Subscription Plan" >
       <div class="block personal fl" v-for="Subscription in Subscriptionlists">
                <h3 class="title">{{Subscription.plan_name}}</h3>
                <!-- CONTENT -->
                <div class="content">
                    <p class="price">
                        <span>${{Subscription.plan_price}}</span>
                        <sub>/mo.</sub>
                    </p>
                </div>
                <!-- /CONTENT -->
                <!-- FEATURES -->
                <ul class="features">
                    <li><span class="fontawesome-cog"></span>{{Subscription.plan_description}}</li>
                </ul>
                <!-- /FEATURES -->
                <!-- PT-FOOTER -->
                <div class="pt-footer"  @click="redeemSubscription(Subscription.id)">
                    <p>Purchase Subscription</p>
                </div>
                <!-- /PT-FOOTER -->
            </div>
            <!-- /PERSONAL -->
          
    </b-modal>
</div>
</template>
<script>
import { mapActions} from "vuex";
import { required, numeric } from "vuelidate/lib/validators";
import moment from "moment";
import Multiselect from 'vue-multiselect';

export default {
  name:'Subscription',
  components: {
    Multiselect
  },
  data() {
    return {
      Subscriptionlists:[],
      listPages:0,
      activePage:1,      
      patientno:'',
      errormsg:'',
      is_API_call_running:false,
      searchtext:'',
      myTimer:null,
     
    }
  },
  mounted() {
    console.log("inside the subcription")
    this.subcriptionList();
  },
  
  methods:{
    ...mapActions("hospital",["subcriptionListData","redeemSubscriptionData"]),
    redeemSubscription(plan_id){
         var bodyFormData = new FormData();
            bodyFormData.append('plan_id', plan_id);
           this.redeemSubscriptionData(bodyFormData).then((response) => {
                console.log(response);
                this.$refs['modal-subcription'].hide()
            });
    },
    subcriptionList(){
            this.subcriptionListData().then((response) => {
              console.log(response);
              this.Subscriptionlists = response.data;
                      this.$refs['modal-subcription'].show()


        });
   
    },

    handleOk(bvModalEvent) {
          bvModalEvent.preventDefault();
          if(this.patientno){
            var bodyFormData = new FormData();
            bodyFormData.append('patient_number', this.patientno);
            this.checkPatientNumberData(bodyFormData).then((response) => {
              if (response.response_code == 200) {
                this.$router.push({name: 'opddetails', params: { 'pid':this.patientno, 'is_edit':0 }});
                this.patientno = ''
              }else{
                this.errormsg = response.message;
              }
            });
          }
        },

   
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
/*
Inspired by the dribble shot http://dribbble.com/shots/1285240-Freebie-Flat-Pricing-Table?list=tags&tag=pricing_table
*/

/*--------- Font ------------*/
@import url(https://fonts.googleapis.com/css?family=Droid+Sans);
/* fontawesome */
[class*="fontawesome-"]:before {
  font-family: 'FontAwesome', sans-serif;
}
* {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
/*------ utiltity classes -----*/
.fl{ float:left; }
.fr{ float: right; }
/*its also known as clearfix*/
.group:before,
.group:after {
    content: "";
    display: table;
} 
.group:after {
    clear: both;
}
.group {
    zoom: 1;  /*For IE 6/7 (trigger hasLayout) */
}

body {
    background: #F2F2F2;
    font-family: 'Droid Sans', sans-serif;
    line-height: 1;
    font-size: 16px;    
}
.wrapper {
    
}
.pricing-table {
    width: 80%;
    margin: 50px auto;
    text-align: center;
    padding: 10px;
    padding-right: 0;
}
.pricing-table .heading{
    color: #9C9E9F;
    text-transform: uppercase;
    font-size: 1.3rem;
    margin-bottom: 4rem;
}
.block{
    width: 30%;    
    margin: 0 15px;
    overflow: hidden;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;    
/*    border: 1px solid red;*/
}
/*Shared properties*/
.title,.pt-footer{
    color: #FEFEFE;
    text-transform: capitalize;
    line-height: 1.5;
    position: relative;
}
.pt-footer{
   line-height: 2.5;
}
.content{
    position: relative;
    color: #FEFEFE;
    padding: 0px 0 10px 0;
}
/*arrow creation*/
.content:after, .content:before,.pt-footer:before,.pt-footer:after {
	top: 100%;
	left: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}
.pt-footer:after,.pt-footer:before{
    top:0;
}
.content:after,.pt-footer:after {
	border-color: rgba(136, 183, 213, 0);	
	border-width: 5px;
	margin-left: -5px;
}
/*/arrow creation*/
.price{
    position: relative;
    display: inline-block;
    margin-bottom: 0.625rem;
}
.price span{    
    font-size: 4rem;
    letter-spacing: 8px;
    font-weight: bold;        
}
.price sup{
    font-size: 1.5rem;    
    position: absolute;    
    top: 12px;
    left: -12px;
}
.hint{
    font-style: italic;
    font-size: 0.9rem;
}
.features{
    list-style-type: none;    
    background: #FFFFFF;
    text-align: left;
    color: #9C9C9C;
    padding:15px 22%;
    font-size: 0.9rem;
}
.features li{
    padding:15px 0;
    width: 100%;
}
.features li span{
   padding-right: 0.4rem; 
}
.pt-footer{
    font-size: 0.95rem;
    text-transform: capitalize;
}
/*PERSONAL*/
.personal .title{        
    background: #78CFBF;    
    text-align:center;
}
.personal .content,.personal .pt-footer{
    background: #82DACA;
      text-align:center;
}
.personal .content:after{	
	border-top-color: #82DACA;	
}
.personal .pt-footer:after{
    border-top-color: #FFFFFF;
}
/*PROFESSIONAL*/
.professional .title{
    background: #3EC6E0;
     text-align:center;
}
.professional .content,.professional .pt-footer{
    background: #53CFE9;
      text-align:center;
}
.professional .content:after{	
	border-top-color: #53CFE9;	
}
.professional .pt-footer:after{
    border-top-color: #FFFFFF;
}
/*BUSINESS*/
.business .title{
    text-align:center;
    background: #E3536C;
}
.business .content,.business .pt-footer{
    background: #EB6379;
      text-align:center;
}
.business .content:after{	
	border-top-color: #EB6379;	
}
.business .pt-footer:after {	
	border-top-color: #FFFFFF;	
}
#modal-subcription{
  width:100%;
}
</style>